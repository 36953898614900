<template>
  <div class="dropdown-wrapper">
    <b-dropdown
      id="dropdown-offset"
      offset="-186"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <DropdownIcon />
      </template>
      <!-- <b-dropdown-item class="triangle"></b-dropdown-item> -->
      <b-dropdown-item
        v-for="(option, i) in options"
        :key="i"
        class="list-group-item"
        @click="setOption(option)"
      >
        {{ option.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import DropdownIcon from "@/components/svg/DropdownIcon.vue";
export default {
  name: "Dropdown",
  components: {
    DropdownIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.options[0],
    };
  },
  methods: {
    setOption(option) {
      this.selected = option;
      this.$emit("selected", option.title);
    },
  },
};
</script>
<style lang="scss" scoped>
// .triangle {
//   display: none;
// }

// @supports (clip-path: inset(50%)) {
//   .triangle {
//     @include triangle;
//   }
// }
</style>
