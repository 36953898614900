<template>
  <div class="status-card card mb-1">
    <div class="status-card__title d-flex justify-content-between align-items-center">
      <p class="text-light mb-0">{{ title }}</p>
      <Dropdown :options="options" @selected="changed" />
    </div>
    <div class="status-card__body d-flex align-items-center">
      <div class="status-card__icon d-flex justify-content-center align-items-center">
        <EarningIcon />
      </div>
      <div class="status-card__info mt-3 ml-3">
        <h5 class="status-card__description">{{ description }}</h5>
        <p class="text-success">{{ status }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import EarningIcon from "../svg/EarningIcon.vue";
import Dropdown from "@/components/Dropdown.vue";
export default {
  name: "StatusCard",
  components: {
    EarningIcon,
    Dropdown,
  },
  props: {
    description: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changed(status) {
      this.$emit("changed", status);
    },
  },
};
</script>
<style lang="scss">
.status-card {
  border-radius: 15px;
  &__title {
    background-color: $dark-blue;
    border-radius: 15px 15px 0 0;
    padding: 0.75rem 0.8rem 0.75rem 2rem;
  }
  &__body {
    padding: 1.5rem 1.5rem;
    @include media-breakpoint-down(xs) {
      padding-left: 1.1rem;
    }
  }
  &__icon {
    width: 3.8rem;
    height: 3.8rem;
    min-width: 3.8rem;
    min-height: 3.8rem;
    background: $tertiary-color;
    border-radius: 50%;
    @include media-breakpoint-down(lg) {
      width: 3rem;
      height: 3rem;
    }
  }
  &__description {
    color: $dark-blue;
    font-weight: 600;
    @include media-breakpoint-down(lg) {
      font-size: 1rem;
    }
  }
}
.dropdown-wrapper {
  .btn {
    height: 30px;
  }
}
</style>
