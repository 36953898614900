<template>
  <div class="card p-4 slide-wrapper">
    <div class="profile-card-slide d-flex justify-content-between align-items-center">
      <button class="bg-light backward-arrow arrow d-flex align-items-center justify-content-center" @click="showPrev"> <CaretLeftIcon /> </button>
      <div class="profile-cards">
        <VueSlickCarousel ref="carousel" v-bind="settings">
          <ProfileCard v-for="(slide, i) in slides" :key="i" :profile="slide" />
        </VueSlickCarousel>
      </div>
      <button class="forward-arrow arrow bg-light d-flex align-items-center justify-content-center" @click="showNext"> <CaretRightIcon /> </button>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import ProfileCard from './ProfileCard.vue';
import CaretRightIcon from '@/components/svg/CaretRightIcon'
import CaretLeftIcon from "@/components/svg/CaretLeftIcon"

export default {
  name: "ProfileCardSlide",
  components: {
    VueSlickCarousel,
    ProfileCard,
    CaretRightIcon,
    CaretLeftIcon
  },
  props: {
    slides: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      settings: {
        "dots": false,
        "arrows": false,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": false,
              "dots": false
            }
          },
          {
            "breakpoint": 991,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
              "initialSlide": 1
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "initialSlide": 1
            }
          },
        ]
      },
    }

  },
  methods: {
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev() {
      this.$refs.carousel.prev()
    },
  },


};
</script>
<style lang="scss" scoped>

.slide-wrapper {
  height: 22em;
  border-radius: 15px;
}

.profile-card-slide {
  height: 100%;

  .profile-cards {
    width: 80%;

    @include media-breakpoint-down(sm) {
      margin-left: 1rem;
    }

  }

  .arrow {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 3px;
    font-size: 1.5rem;
  }

}
</style>