<template>
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0.333496C9.84628 0.333496 8.71846 0.675615 7.75917 1.31659C6.79989 1.95756 6.05221 2.86861 5.6107 3.93451C5.16919 5.00041 5.05367 6.1733 5.27875 7.30486C5.50383 8.43641 6.0594 9.47581 6.87521 10.2916C7.69102 11.1074 8.73042 11.663 9.86197 11.8881C10.9935 12.1132 12.1664 11.9976 13.2323 11.5561C14.2982 11.1146 15.2093 10.3669 15.8502 9.40766C16.4912 8.44837 16.8333 7.32055 16.8333 6.16683C16.8333 4.61973 16.2188 3.136 15.1248 2.04204C14.0308 0.948078 12.5471 0.333496 11 0.333496ZM11 9.66683C10.3078 9.66683 9.63108 9.46156 9.0555 9.07697C8.47993 8.69239 8.03133 8.14576 7.76642 7.50622C7.50151 6.86668 7.4322 6.16295 7.56725 5.48401C7.7023 4.80508 8.03564 4.18144 8.52513 3.69196C9.01461 3.20247 9.63825 2.86913 10.3172 2.73408C10.9961 2.59903 11.6999 2.66834 12.3394 2.93325C12.9789 3.19816 13.5256 3.64676 13.9101 4.22233C14.2947 4.79791 14.5 5.47459 14.5 6.16683C14.5 7.09509 14.1313 7.98533 13.4749 8.6417C12.8185 9.29808 11.9283 9.66683 11 9.66683ZM21.5 22.5002V21.3335C21.5 19.1676 20.6396 17.0903 19.108 15.5588C17.5765 14.0272 15.4993 13.1668 13.3333 13.1668H8.66667C6.50073 13.1668 4.42351 14.0272 2.89196 15.5588C1.36041 17.0903 0.5 19.1676 0.5 21.3335V22.5002H2.83333V21.3335C2.83333 19.7864 3.44791 18.3027 4.54188 17.2087C5.63584 16.1147 7.11957 15.5002 8.66667 15.5002H13.3333C14.8804 15.5002 16.3642 16.1147 17.4581 17.2087C18.5521 18.3027 19.1667 19.7864 19.1667 21.3335V22.5002H21.5Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default{
 name:"TotalHiredIcon"
}
</script>