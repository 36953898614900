<template>
  <div>
    <div class="dashboard-summary">
      <div class="total px-2 px-sm-5 py-3 d-flex justify-content-between flex-wrap">
        <WorkSummary class="mb-3" value="20" title="Total Hired Employees" :add-new="addNew" @add="addEmployee()"
          @viewEmployees="addNewTalent()">
          <template v-slot:icon>
            <TotalHiredIcon />
          </template>
        </WorkSummary>
        <WorkSummary class="mb-3" value="100 Hour" title="Total hours worked" />
      </div>
    </div>
    <div class="dashboard-content">
      <div class="cards flex d-flex justify-content-between my-4 flex-wrap">
        <div class="profileslide-card">
          <ProfileCardSlide :slides="slides" />
        </div>
        <div class="status-cards d-flex flex-column">
          <StatusCard class="mb-3" description="Last Billing status" title="Billing Status" status="Invoice Sent"
            :options="billingStatuses" @changed="updateBilling" />
          <ProgressStatus date="28 Oct,2021" description="Next Billing Cycle" progress="23.4%" />
        </div>
      </div>
      <div class="mb-3">
        <HiringCard />
      </div>
    </div>
    <RequestTalentModal :projects="projects" :hiring-type="hiring_type" @submit="requestTalent" />
  </div>
</template>
<script>
import WorkSummary from "../../components/dashboard/WorkSummary.vue";
import StatusCard from "../../components/dashboard/StatusCard.vue";
import ProgressStatus from "../../components/dashboard/ProgressStatus.vue";
import HiringCard from "../../components/dashboard/HiringCard.vue";
import ProfileCardSlide from "../../components/dashboard/ProfileCardSlide.vue";
import TotalHiredIcon from "../../components/svg/TotalHiredIcon.vue";
import RequestTalentModal from "@/components/employee/RequestTalentModal.vue";
import skillData from '@/data/skills.json';
import { mapActions, mapState } from "vuex";

export default {
  components: {
    WorkSummary,
    StatusCard,
    ProgressStatus,
    HiringCard,
    ProfileCardSlide,
    TotalHiredIcon,
    RequestTalentModal,
  },
  data() {
    return {
      addNew: true,
      billingStatuses: [
        {
          name: "Invoice Sent",
          value: 0,
        },
      ],
      payload: {},
      slides: skillData.slides,
      hiring_type: {
        "Full time": 1,
        "Part time": 0
      }
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects
    })
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    ...mapActions({
      fetchProjects: "projects/fetchProjects",
    }),
    updateBilling() {
      // console.log("billingUpdated");
    },
    addNewTalent() {
      this.$router.push('/dashboard/employees')
    },
    addEmployee() {
      this.$bvModal.show('request_talent')
    },
    requestTalent(form) {
      this.payload = form;
      this.$store
        .dispatch("employees/requestTalent", this.payload)
        .then(() => {
          this.$bvModal.hide('request_talent')
        })
        .catch((err) => {
          let data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
.total {
  background-color: $body-bg;
  border: 1px solid #d6dde4;
  border-radius: 10px;
}

.status-cards {
  width: 34%;

  @include media-breakpoint-down(lg) {
    width: 48%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-top: 2em;
  }
}

.profileslide-card {
  width: 64%;

  @include media-breakpoint-down(lg) {
    width: 50%;
  }

  @include media-breakpoint-down(md) {
    min-width: 100%;
  }
}
</style>
