<template>
  <div>
    <b-form-group :label="label" label-for="search">
      <b-form-input id="search" v-model="searchedData" :value="value" required class="search__input" type="text"
                    :placeholder="placeholder" @input="runSearchData"
      >
      </b-form-input>
    </b-form-group>
    <div v-if="toggleSearchResults" class="search__results p-1 rounded-sm">
      <div v-for="(data, index) in filteredData" :key="index"
           class="search__result p-2 font-weight-bold text-muted hover:text-primary" @click="dataSelected(data)"
      >
        {{ data.name }}
      </div>
      <span v-if="create" class="pl-1"><router-link to="/dashboard/projects">Project Unavailable, click here to view projects.</router-link></span>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        searchData: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            searchedData: '',
            filteredData: [],
            toggleSearchResults: false,
            create: false
        }
    },
    methods: {
        runSearchData() {
            if (this.searchedData !== '') {
                this.filteredData = this.searchData.filter((data) => data.name.toLowerCase().includes(this.searchedData.toLowerCase()))
                this.create = false
                this.toggleSearchResults = true
                if (this.filteredData == '') {
                    this.create = true
                }
            } 
            else {
                this.showWarning = false
            }
        },
        dataSelected(data) {
            this.$emit('dataSelected', data)
            this.searchedData = data.name
            this.toggleSearchResults = false
        },
    },

}
</script>

<style lang="scss" scoped>
.search {
    &__input {
        text-transform: capitalize;
    }

    &__input::placeholder {
        color: #AFBAC6
    }

    &__results {
        border: 1px solid #9ACAF5;
        outline: 5px solid white;
        outline-offset: -8px;
        cursor: pointer;
        text-transform: uppercase;
    }

    &__result {
        border-bottom: 1px solid #9ACAF5;
    }

    &__result:hover {
        color: #9ACAF5;
    }
}
</style>