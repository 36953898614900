<template>
  <div class="profile-card py-3 m-rounded-5">
    <div class="text-center">
      <img class="profile-card__image" :src="profile.imgsrc" :alt="profile.name" />
    </div>
    <div class="profile-card__info ml-3 mt-2 m-fs-14">
      <span class="profile-card__name d-block">{{ profile.name }}</span>
      <span class="profile-card__title mt-1">{{ profile.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfileCard",
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-card {
  background-color: $blue-tint-5;
  color: $dark-blue;
  width: 94% !important;

  &__image {
    width: 135px;
    height: 135px;
    border-radius: 50%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      width: 120px;
      height: 120px;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 10px !important;
    }
  }

  &__title {
    font-weight: 700;
  }
}
</style>
