<template>
  <primary-modal ref="requestTalent" modal-id="request_talent" title="Request Talent">
    <div class="request-talent p-3">
      <b-form ref="requestTalentForm" @submit.prevent="onSubmit">
        <SearchDropdown v-if="!project" :search-data="projects" :label="'Project'" :placeholder="'Search Project'"
          class="mb-2" @dataSelected="projectSelected" />

        <b-form-group v-if="project" label="Project">
          <b-form-input readonly :placeholder="project.name" class="request-talent__input mb-2"></b-form-input>
        </b-form-group>

        <b-form-group label="Skill">
          <b-form-input v-model="form.skills" placeholder="Enter Skill" class="request-talent__input mb-2"></b-form-input>
        </b-form-group>

        <b-form-group label="Number Of Talents">
          <b-form-input v-model="form.no_of_talents" placeholder="Enter Number Of Talents"
            class="request-talent__input mb-2"></b-form-input>
        </b-form-group>

        <div>
          <label for="hiring-type">Select Hiring type</label>
          <div id="hiring-type" role="button"
            class="request-talent__select d-flex justify-content-between align-items-center p-2 mb-1"
            @click="dropdown = !dropdown">
            <span>{{ form.hiring_type ? 'Full Time' : 'Part Time' }}</span>
            <DropdownIcon stroke="black" />
          </div>
          <div v-if="dropdown" class="border">
            <div v-for="(key, value, index) in hiringType" :key="index" role="button" class="px-2 py-1"
              @click="selectHiringType(key)">
              {{ value }}
            </div>
          </div>
        </div>

        <!-- <b-button block type="submit" variant="primary" class="mt-5" @click="onSubmit">Submit</b-button> -->

        <div class="form-group m-mt-40">
          <b-button block variant="primary" @click="onSubmit">
            {{ processing ? "" : "Submit" }}
            <RingLoader v-if="processing" />
          </b-button>
        </div>
      </b-form>
    </div>
  </primary-modal>
</template>

<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import SearchDropdown from "@/components/SearchDropdown.vue";
import DropdownIcon from "@/components/svg/DropdownIcon.vue";
import RingLoader from "@/components/loader/RingLoader";

export default {
  components: {
    PrimaryModal,
    SearchDropdown,
    DropdownIcon,
    RingLoader 
  },
  props: {
    projects: {
      type: Array,
      default: () => []
    },
    project: {
      type: Object,
      default: () => { }
    },
    hiringType: {
      type: Object,
      default: () => { }
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        project: this.projects ? this.project : '',
        skills: '',
        hiring_type: 1,
        no_of_talents: ''
      },
      dropdown: false
    }
  },
  mounted() {
    this.project ? "xxx" : this.form.project = {};
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
    projectSelected(value) {
      this.form.project = value
    },
    selectHiringType(value) {
      this.form.hiring_type = value
      this.dropdown = false
    },
  },
};
</script>

<style lang="scss" scoped>
.request-talent {
  &__input {
    font-size: 14px;
    color: $grey-tint-2;
  }

  &__input::placeholder {
    color: $grey-tint-2;
  }

  &__select {
    border: 1px solid $grey-tint-6;
    border-radius: 5px;
  }
}
</style>
