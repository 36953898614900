<template>
  <div class="hiring-card card px-2 pb-3">
    <div class="hiring-card__title d-flex justify-content-between mt-1 p-3">
      <h5 class="hiring-card__heading">Hiring T&C</h5>
      <span class="hiring-card__link ml-2">
        Learn more
        <ArrowRight class="ml-2 d-none d-sm-inline" />
      </span>
    </div>
    <div class="hiring-card__body px-3 mt-3">
      <h6 class="hiring-card__subheading">Srspiciatis unde omnis iste</h6>
      <p class="mt-3">
        Srspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
        laudantium, totam rem aperiam, Srspiciatis unde omnis iste natus error sit
        voluptatem accusantium doloremque laudantium, totam rem aperiam,
      </p>
      <p class="my-3">
        Srspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
        laudantium, totam rem aperiam, Srspiciatis unde omnis iste natus error sit
        voluptatem accusantium doloremque laudantium, totam rem aperiam,
      </p>
      <ul class="hiring-card__list list-unstyled">
        <li>1. Srspiciatis unde omnis iste na</li>
      </ul>
    </div>
  </div>
</template>
<script>
import ArrowRight from "../svg/ArrowRight.vue";
export default {
  name: "HiringCard",
  components: { ArrowRight },
};
</script>
<style lang="scss" scoped>
.hiring-card {
  color: $dark-blue;
  border-radius: 10px 10px 0 0;
  @include media-breakpoint-down(xs) {
    font-size: 0.875rem;
  }
  &__title {
    background: $grey;
    color: $faint-blue;
    border-radius: 10px 10px 0 0;
  }
  &__heading {
    font-size: 20px;
    font-weight: 700;
    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }
  &__link {
    font-size: 14px;
  }
  &__subheading {
    font-size: 1.2em;
    font-weight: 600;
  }
}
</style>
