<template>
  <div class="progress-status card">
    <div class="d-flex align-items-start">
      <div class="progress-status__icon d-flex justify-content-center align-items-center">
        <CardSendIcon />
      </div>
      <div class="progress-status__info ml-3">
        <h5 class="text-white">
          <strong>{{ description }}</strong>
        </h5>
        <p class="progress-status__description mt-1">{{ date }}</p>
      </div>
      <span class="progress-status__data-change text-success ml-3 ml-sm-5">
        <Arrowup class="mr-1 mb-1" />
        <span>{{ progress }}</span>
      </span>
    </div>
  </div>
</template>
<script>
import CardSendIcon from "../svg/CardSendIcon.vue";
import Arrowup from "../svg/Arrowup.vue";
export default {
  name: "ProgressStatus",
  components: {
    Arrowup,
    CardSendIcon,
  },
  props: {
    date: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    progress: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-status {
  background-color: $dark-blue;
  color: white;
  border-radius: 15px;
  padding: 3rem 0 3rem 1.5rem;
  @include media-breakpoint-down(xs) {
    padding-left: 1.1rem;
  }
  &__icon {
    background-color: $light-blue;
    width: 3.8rem;
    height: 3.8rem;
    min-width: 3.8rem;
    min-height: 3.8rem;
    border-radius: 50%;
  }
  &__data-change {
    font-size: 10px;
  }
  &__description {
    font-size: 0.75rem;
    color: $grey;
  }
}
</style>
