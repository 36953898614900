<template>
  <div class="work-summary d-flex justify-content-between px-3">
    <div class="d-flex py-3">
      <div class="work-summary__icon d-flex justify-content-center align-items-center">
        <slot name="icon">
          <EarningIcon />
        </slot>
      </div>
      <div class="work-summary__info ml-3">
        <h5 class="work-summary__value">
          <strong>{{ value }}</strong>
        </h5>
        <span class="work-summary__title mt-1 d-block" :class="{ 'work-summary__title--underline': addNew }"
          @click="viewEmployees">{{ title }}</span>
      </div>
    </div>
    <div v-if="addNew" class="work-summary__add-icon my-3 m-cursor-pointer" @click="$emit('add')">
      <AddIcon />
    </div>
  </div>
</template>
<script>
import EarningIcon from "@/components/svg/EarningIcon";
import AddIcon from "@/components/svg/AddIcon"

export default {
  name: "WorkSummary",
  components: {
    EarningIcon,
    AddIcon
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    addNew: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    viewEmployees() {
      if (this.addNew) {
        this.$emit('viewEmployees')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.work-summary {
  background-color: $blue-tint-5;
  border-radius: $size-10 / 2;
  width: 48%;
  min-width: 220px;

  @include media-breakpoint-down(md) {
    width: 100%;
    min-width: 180px;
  }

  &__icon {
    background-color: $dark-blue;
    border-radius: 5px;
    width: 71px;
    height: 60px;

    @include media-breakpoint-down(sm) {
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
    }
  }

  &__add-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #5daef8;
  }

  &__value {
    font-size: 1.375rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.125rem;
    }
  }

  &__title {
    font-size: 0.875rem;
    @include add-ellipsis;
  }

  &__title {
    &--underline {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
